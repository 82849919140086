import Page from "../Components/Page";
import MainButton from "../Components/Buttons/MainButton";
import FormTextField from "../Components/FormInputElements/FormTextField";
import FormSelectField from "../Components/FormInputElements/FormSelectField";
import Divider from "@mui/material/Divider";

import BooleanQuestion from "../Components/FormInputElements/BooleanQuestion";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate, useParams } from "react-router-dom";

import MapComponent from "../Components/MapComponent";

import {
  SurfaceFormTypes,
  SlopeClassTypes,
  SoilTypes,
  SurfaceSoilTextureTypes,
  TillageRegimeTypes,
} from "../Assets/DataTypes";

import { useEffect, useState } from "react";

import { FieldContext } from "../App";
import { FarmContext } from "../App";
import { SECRETS_CONTEXT } from "../App";
import { GetSetFarm } from "../Components/axiosFetchers";

import { useContext } from "react";
import { axiosInstance } from "../Components/axiosFetchers";
import MapCoordinates from "../Assets/Map/Map_Dataset";
import { getNearestCoordinate } from "../Assets/Map/getNearestCoordinates";

import { useAuth } from "../Components/Auth/useAuth";
import WarningMessageModal from "../Components/WarningMessageModal";

var errorFields = {
  name: false,
  fieldSize: false,
  fieldAddress: false,
  surfaceForm: false,
  slopeClass: false,
  soilType: false,
  surfaceSoilTexture: false,
  tillageRegime: false,
  previousTillageRegime: false,
};

export default function AddFieldPage() {
  const { user } = useAuth();
  const [ErrorFound, setErrorFound] = useState(false);
  const [drawFieldBoundaries, setDrawFieldBoundaries] = useState(false);
  const [addressQuery, setAddressQuery] = useState("Canada");
  const navigate = useNavigate();
  const { farmId } = useParams();
  const [isTypingAddress, setIsTypingAddress] = useState(false);

  const fieldContext = useContext(FieldContext);
  const farmContext = useContext(FarmContext);
  const SECRETS = useContext(SECRETS_CONTEXT);
  const [warningOpen, setWarningOpen] = useState(false);

  document.title = "Add Field Page - Field To Market Canada";

  useEffect(() => {
    GetSetFarm(farmId, user, farmContext);
    fieldContext.resetState();
  }, [farmId]);

  function handleStateChange(target, key) {
    const newValue = {};
    newValue[key] = target;
    fieldContext.setter({ ...fieldContext.state, ...newValue });
    errorFields[key] = false;
  }

  async function handleAddressChange(address) {
    setIsTypingAddress(false);
    setAddressQuery(address);
    //

    if (!address) return;
    try {
      const geoData = await axiosInstance.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${SECRETS.SECRETS.GOOGLE_APIKEY}`
      );

      let lat = geoData.data.results[0].geometry.location.lat;
      let lng = geoData.data.results[0].geometry.location.lng;

      const SoilData = getNearestCoordinate(
        farmContext.state.province,
        lat,
        lng,
        MapCoordinates
      );

      const newValues = {};
      newValues["surfaceForm"] = SoilData.LF_TYPE;
      newValues["slopeClass"] = SoilData.LF_SLOPE;
      newValues["Ecodistrict"] = SoilData.Ecodistrict;
      newValues["SLCpolygon"] = SoilData.SLCpolygon;
      newValues["soilCode"] = SoilData["SOIL_CODE"];

      fieldContext.setter({ ...fieldContext.state, ...newValues });
      errorFields.surfaceForm = false;
      errorFields.slopeClass = false;
    } catch (error) {
      console.log("An error occurred while fetching map data");
    }
  }

  function handleSaveAndAddFIeld() {
    if (!fieldContext.state.hasBoundaries) {
      setWarningOpen(true);
      return;
    }
    if (isInputValid()) {
      axiosInstance
        .post(
          process.env.REACT_APP_API_URL +
            `/farms/${farmContext.state._id.$oid}/fields`,
          {
            farmId: farmContext.state.id,
            name: fieldContext.state.name,
            fieldSize: fieldContext.state.fieldSize,
            fieldAddress: fieldContext.state.fieldAddress,
            surfaceForm: fieldContext.state.surfaceForm,
            slopeClass: fieldContext.state.slopeClass,
            soilType: fieldContext.state.soilType,
            soilCode: fieldContext.state.soilCode,
            surfaceSoilTexture: fieldContext.state.surfaceSoilTexture,
            tillageRegime: fieldContext.state.tillageRegime,
            previousTillageRegime: fieldContext.state.previousTillageRegime,
            regimeChangeDate: fieldContext.state.regimeChangeDate,
            Ecodistrict: fieldContext.state.Ecodistrict,
            SLCpolygon: fieldContext.state.SLCpolygon,
            fieldBoundariesSize: fieldContext.state.fieldBoundariesSize,
            hasBoundaries: fieldContext.state.hasBoundaries,
            boundariesArray: fieldContext.state.boundariesArray,
          },
          {
            headers: {
              token: "Bearer " + user.token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          navigate(
            `/farm/${farmId}/field/${response.data.fieldId}/addcropyear`
          );
        });
    }
  }

  function isInputValid() {
    errorFields.name = fieldContext.state.name.trim() === "";
    errorFields.fieldSize = fieldContext.state.fieldSize.trim() === "";
    errorFields.fieldAddress = fieldContext.state.fieldAddress.trim() === "";
    errorFields.surfaceForm = fieldContext.state.surfaceForm.trim() === "";
    errorFields.slopeClass = fieldContext.state.slopeClass.trim() === "";
    errorFields.soilType = fieldContext.state.soilType.trim() === "";
    errorFields.surfaceSoilTexture =
      fieldContext.state.surfaceSoilTexture.trim() === "";
    errorFields.tillageRegime = fieldContext.state.tillageRegime.trim() === "";
    errorFields.previousTillageRegime =
      fieldContext.state.previousTillageRegime.trim() === "";

    if (
      errorFields.name ||
      errorFields.fieldSize ||
      errorFields.fieldAddress ||
      errorFields.surfaceForm ||
      errorFields.slopeClass ||
      errorFields.soilType ||
      errorFields.surfaceSoilTexture ||
      errorFields.tillageRegime ||
      errorFields.previousTillageRegime
    ) {
      setErrorFound(true);
      return false; // return false because input is invalid
    } else {
      setErrorFound(false);
      return true;
    }
  }

  return (
    <Page
      title={"New Field" + " on " + farmContext.state.name}
      headerBorderColor={"border-[#34a853]"}
      padding={"p-[0px]"}
    >
      <div className="flex w-full h-full">
        {/* LEFT PANEL | INPUT AREA */}
        <div className="flex  relative flex-col justify-end h-full w-full md:w-[336px] md:min-w-[336px] border-r border-[rgb(211,211,211)]">
          {/* FIELDS*/}
          <div
            style={{
              height: "calc(100% - 124.5px)",
            }}
            className="flex absolute flex-col  top-0 left-0 overflow-auto w-full p-4 "
          >
            <FormTextField
              errorFound={errorFields.name}
              fieldState={fieldContext.state.name}
              fieldLabel={"Field Name"}
              modalTitle={"Field Name"}
              onChange={(t) => handleStateChange(t, "name")}
              modalDescription={
                "Enter the name of your field. Use a name you will recognize."
              }
            />
            <FormTextField
              errorFound={errorFields.fieldSize}
              fieldState={fieldContext.state.fieldSize}
              fieldLabel={"Field Size [Acres]"}
              modalTitle={"Field Size"}
              onChange={(t) => handleStateChange(t, "fieldSize")}
              isNumber={true}
              modalDescription={
                "Enter the size of your field. Please use Acres as a unit of measurement, since the system will expects this unit to compute the analysis."
              }
            />
            <FormTextField
              errorFound={errorFields.fieldAddress}
              fieldState={fieldContext.state.fieldAddress}
              onChange={(t) => handleStateChange(t, "fieldAddress")}
              fieldLabel={"Field Address Look Up"}
              modalTitle={"Field Address"}
              modalDescription={
                "Enter the address  of your field. Please use the format [Street, City, Postal Code, Province]."
              }
              onBlur={handleAddressChange}
              onFocus={() => {
                setIsTypingAddress(true);
              }}
            />
            <div>
              <FormTextField
                errorFound={false}
                fieldState={fieldContext.state.fieldBoundariesSize}
                onChange={console.log}
                fieldLabel={"Calculated Acres from Field Boundaries"}
                modalTitle={"Calculated Field Acres "}
                modalDescription={
                  "This area is the approximate size of the field boundaries drawing provided. It's calculated by measuring the area of the polygon created by the vertices added onto the map."
                }
              />
            </div>

            <BooleanQuestion
              fieldValue={fieldContext.state.hasBoundaries}
              fieldLabel={"Field Boundaries Provided?"}
              modalTitle={"Field Boundaries"}
              modalDescription={
                "Providing your field boundaries allows us to have the a list of the surrounding coordinates of your field, this allows us to dynamically calculate your field's size, and it is very useful for documentation purposes."
              }
            />
            {/* DIVIDER */}
            <Divider sx={{ marginBottom: 3 }} />
            <FormSelectField
              errorFound={errorFields.surfaceForm}
              valuesArray={SurfaceFormTypes}
              onChange={(e) => handleStateChange(e.target.value, "surfaceForm")}
              fieldLabel={"Surface Form"}
              fieldState={fieldContext.state.surfaceForm}
              helperText={""}
              modalTitle={"Surface Form"}
              modalDescription={
                "Refers to parallel and subparallel steep-sided, and narrow ravines that have developed from fluvial erosion on landscapes with strong to steep slopes."
              }
            />
            <FormSelectField
              errorFound={errorFields.slopeClass}
              valuesArray={SlopeClassTypes}
              fieldLabel={"Slope Class"}
              fieldState={fieldContext.state.slopeClass}
              onChange={(e) => handleStateChange(e.target.value, "slopeClass")}
              helperText={""}
              modalTitle={"Slope Class"}
              modalDescription={
                "Slope percent (%) is the steepness of a sloping area in your field. Slope percent impacts erosion on your field. We recommend determining the percent slope using a clinometer measuring the dominant slope acrossthe field. If do not have access to a clinometer, the information in the following table maybe be useful. First select the approximate field size (acres) and/or field width/length (feet), then identify the approximate elevation change (feet) over the field and the correlating slope percent."
              }
            />
            <FormSelectField
              errorFound={errorFields.soilType}
              valuesArray={SoilTypes}
              fieldLabel={"Soil Type"}
              fieldState={fieldContext.state.soilType}
              onChange={(e) => handleStateChange(e.target.value, "soilType")}
              helperText={""}
              modalTitle={"Soil Type"}
              modalDescription={"Some helpful description."}
            />
            <FormSelectField
              errorFound={errorFields.surfaceSoilTexture}
              valuesArray={SurfaceSoilTextureTypes}
              fieldLabel={"Surface Soil Texture"}
              fieldState={fieldContext.state.surfaceSoilTexture}
              onChange={(e) =>
                handleStateChange(e.target.value, "surfaceSoilTexture")
              }
              helperText={""}
              modalTitle={"Surface Soil Texture"}
              modalDescription={
                "Dominant surface soil texture class for the field boundary."
              }
            />
            {/* DIVIDER */}
            <Divider sx={{ marginBottom: 3 }} />
            <FormSelectField
              errorFound={errorFields.tillageRegime}
              valuesArray={TillageRegimeTypes}
              fieldLabel={"Tillage Regime"}
              fieldState={fieldContext.state.tillageRegime}
              onChange={(e) =>
                handleStateChange(e.target.value, "tillageRegime")
              }
              helperText={""}
              modalTitle={"Tillage Regime"}
              modalDescription={"Some helpful description."}
            />
            <FormSelectField
              errorFound={errorFields.previousTillageRegime}
              valuesArray={TillageRegimeTypes}
              fieldLabel={"Previous Tillage Regime"}
              fieldState={fieldContext.state.previousTillageRegime}
              onChange={(e) =>
                handleStateChange(e.target.value, "previousTillageRegime")
              }
              helperText={""}
              modalTitle={"Previous Tillage Regime"}
              modalDescription={"Some helpful description."}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disableFuture
                onChange={(event) => {
                  if (event && !isNaN(event.$d))
                    handleStateChange(
                      event.$d.toISOString(),
                      "regimeChangeDate"
                    );
                }}
                label="Regime Change Date"
              />
            </LocalizationProvider>
          </div>

          <div className=" p-[14px] flex flex-col justify-between w-full h-[124.5px] border-t border-[rgb(211,211,211)]">
            <MainButton text={"Save Field"} onClick={handleSaveAndAddFIeld} />
            <MainButton
              text={"Cancel"}
              onClick={() => navigate(`/farm/${farmId}`)}
              secondary={true}
            />
          </div>
        </div>

        {/* MAP  */}
        <div className="hidden md:block w-full h-full">
          <MapComponent
            GOOGLE_APIKEY={SECRETS.SECRETS.GOOGLE_APIKEY}
            address={addressQuery ? addressQuery : "Canada"}
            drawFieldBoundaries={true}
            setDrawFieldBoundaries={console.log}
            isTypingAddress={isTypingAddress}
          />
        </div>
        <WarningMessageModal
          title={"Field Boundaries Required"}
          description={
            "In order to save your field you need to draw and save your field's boundaries. Please use the controls on the top left corner of the map window to draw, and save the polygon representative of your field's boundaries."
          }
          open={warningOpen}
          handleClose={setWarningOpen}
        />
      </div>
    </Page>
  );
}
