import { FourRAssessmentContext } from "../../../App";

import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Box,
  Checkbox,
  Typography,
  FormControlLabel,
} from "@mui/material";

export default function FourRCompleteStep() {
  const fourRAssessmentContext = useContext(FourRAssessmentContext);

  useEffect(() => {}, [fourRAssessmentContext.state]);

  function handleCheck(elementCode, key, value) {
    const prev = { ...fourRAssessmentContext.state };

    prev.operationsQuestionnaire[elementCode][key] = value;
    fourRAssessmentContext.setter(prev);
  }

  return <Box display="flex">Complete</Box>;
}
