import {
  CropYearContext,
  FarmContext,
  FourRAssessmentContext,
  GUI_CONTEXT,
} from "../../../App";
import { FertilizerTypes } from "../../../Assets/DataTypes";
import React, { useContext, useEffect, useState } from "react";
import {
  FOUR_R_SOIL_TEST_CREATOR,
  FOUR_R_ASSESSMENT_CREATOR,
  FOUR_R_FERTILIZER_APPLICATION_CREATOR,
} from "../../../Assets/contextFactories_V0";
import SoilTest from "./SoilTest";
import FertilizerApplication4R from "./FertilizerApplication4R";
import RatesSection from "./FourRRatesSection";
import CompleteStep from "./FourRCompleteStep";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Typography,
} from "@mui/material";

const colorsote = {
  main: "#F15D22",
  light: "#0000FF",
  dark: "#a8370b",
  contrastText: "#fff",
};

const theme = createTheme({
  palette: {
    primary: colorsote,
  },
});

export default function FourRAssessment({}) {
  const cropyearContext = useContext(CropYearContext);
  const fourRAssessmentContext = useContext(FourRAssessmentContext);
  const farmContext = useContext(FarmContext);

  return (
    <ThemeProvider theme={theme}>
      <section
        style={{ margin: "50px 0" }}
        className={`transition-all duration-300  w-full  overflow-hidden`}
      >
        <h4 className="text-[rgb(102,102,102)] font-extralight text-[36px]">
          4R Stewardship Project
        </h4>
        <NutrientStewardship />
      </section>
    </ThemeProvider>
  );
}

const steps = ["Soil Testing", "Fertilizer Applications", "Fertilizer Rates"];

function NutrientStewardship() {
  const GUI = useContext(GUI_CONTEXT);
  const [activeStep, setActiveStep] = useState(0);
  const [soilTestsErrors, setSoilTestsErrors] = useState([]);
  const [fertilizerApplicationsErrors, setFertilizerApplicationsErrors] =
    useState([]);
  const fourRAssessmentContext = useContext(FourRAssessmentContext);

  var defaultSoilTestError = {
    nutrient: false,
    test: false,
    date: false,
    result: false,
    units: false,
    frequency: false,
  };
  var defaultFertApplicationError = {
    applicationDate: false,
    applicationMethod: false,
    fertilizerTiming: false,
    soilState: false,
    fertilizerMixId: false,
  };

  function handleValidateSoilTests() {
    const Errors = [];
    let FoundError = false;

    for (var test of fourRAssessmentContext.state.soilTests) {
      var err = {};
      let properties = [
        "nutrient",
        "test",
        "date",
        "result",
        "units",
        "frequency",
      ];
      for (let P of properties) {
        err[P] = test[P] === "";
        if (test[P] === "") FoundError = true;
      }

      Errors.push(err);
    }
    if (FoundError) return { isValid: false, errors: Errors };
    else return { isValid: true, errors: [] };
  }

  function handleValidateFertApplications() {
    const Errors = [];
    let FoundError = false;

    for (var fert of fourRAssessmentContext.state.fertilizerApplications) {
      var err = {};
      let properties = [
        "applicationDate",
        "applicationMethod",
        "fertilizerTiming",
        "soilState",
        "fertilizerMixId",
      ];
      for (let P of properties) {
        err[P] = fert[P] === "";
        if (fert[P] === "") FoundError = true;
      }

      Errors.push(err);
    }
    if (FoundError) return { isValid: false, errors: Errors };
    else return { isValid: true, errors: [] };
  }

  const handleNext = () => {
    if (activeStep === 0) {
      // Validate SOilTests
      const result = handleValidateSoilTests();

      setSoilTestsErrors(result.errors);

      if (result.isValid === false) return;
    }

    if (activeStep === 1) {
      const result = handleValidateFertApplications();
      setFertilizerApplicationsErrors(result.errors);
      if (result.isValid === false) return;
    }

    // All good go to next step
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    if (activeStep >= 2) {
      GUI.setter({ ...GUI.state, completed4R: true });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function SoilTestingTable() {
    const fourRAssessmentContext = useContext(FourRAssessmentContext);
    useEffect(() => {}, [fourRAssessmentContext.state]);

    function handleAddSoilTest() {
      const prev = { ...fourRAssessmentContext.state };
      prev.soilTests.push(FOUR_R_SOIL_TEST_CREATOR());
      fourRAssessmentContext.setter(prev);
    }

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nutrient</TableCell>
              <TableCell>Test</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Result</TableCell>
              <TableCell>Units</TableCell>
              <TableCell>Frequency</TableCell>
              <TableCell>Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fourRAssessmentContext.state.soilTests.map((row, index) => (
              <SoilTest
                key={index}
                index={index}
                data={row}
                error={
                  index <= soilTestsErrors.length - 1
                    ? soilTestsErrors[index]
                    : defaultSoilTestError
                }
              />
            ))}
          </TableBody>
          <TableBody>
            <TableRow>
              {" "}
              <TableCell>
                <Button onClick={handleAddSoilTest} size="small">
                  Add New Test
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function FertilizerApplicationTable() {
    const fourRAssessmentContext = useContext(FourRAssessmentContext);
    useEffect(() => {}, [fourRAssessmentContext.state]);

    function handleAddFertilizerApplication() {
      const prev = { ...fourRAssessmentContext.state };
      prev.fertilizerApplications.push(FOUR_R_FERTILIZER_APPLICATION_CREATOR());
      fourRAssessmentContext.setter(prev);
    }

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Timing of Application</TableCell>
              <TableCell>Method of Application</TableCell>
              <TableCell>Fertilizer Timing</TableCell>
              <TableCell>Depth of Incorporation</TableCell>
              <TableCell>Soil State</TableCell>
              <TableCell>Fertilizer Mix</TableCell>
              <TableCell>Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fourRAssessmentContext.state.fertilizerApplications.map(
              (row, index) => (
                <FertilizerApplication4R
                  key={index}
                  index={index}
                  data={row}
                  error={
                    index <= fertilizerApplicationsErrors.length - 1
                      ? fertilizerApplicationsErrors[index]
                      : defaultFertApplicationError
                  }
                />
              )
            )}
          </TableBody>
        </Table>
        <div className="w-full p-2">
          <Button
            onClick={handleAddFertilizerApplication}
            sx={{ width: 200 }}
            size="small"
          >
            Add Application
          </Button>
        </div>
      </TableContainer>
    );
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return SoilTestingTable();
      case 1:
        return FertilizerApplicationTable();
      case 2:
        return RatesSection();
      default:
        return CompleteStep();
    }
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>{getStepContent(activeStep)}</StepContent>
          </Step>
        ))}
      </Stepper>
      <Box>
        <Box mt={2}>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          {activeStep < steps.length && (
            <Button variant="contained" onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          )}
        </Box>
        {/* {activeStep === steps.length && (
          <Box mt={2}>
            <Typography>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        )} */}
      </Box>
    </Box>
  );
}
