import { FourRAssessmentContext } from "../../../App";

import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Box,
  Checkbox,
  Typography,
  FormControlLabel,
} from "@mui/material";

export default function RatesSection() {
  const fourRAssessmentContext = useContext(FourRAssessmentContext);

  useEffect(() => {}, [fourRAssessmentContext.state]);

  function handleCheck(elementCode, key, value) {
    const prev = { ...fourRAssessmentContext.state };

    prev.operationsQuestionnaire[elementCode][key] = value;
    fourRAssessmentContext.setter(prev);
  }

  return (
    <Box display="flex">
      {[
        { code: "N", label: "Nitrogen" },
        { code: "P", label: "Phosphorous" },
      ].map((ELEMENT, index) => (
        <Box key={index}>
          <Typography variant="h6">{ELEMENT.label}</Typography>
          <FormControlLabel
            sx={{ display: "block" }}
            control={
              <Checkbox
                onChange={(e) =>
                  handleCheck(
                    ELEMENT.code,
                    "Use_A_Nutrient_Balance_Calculator",
                    e.target.checked
                  )
                }
                checked={
                  fourRAssessmentContext.state.operationsQuestionnaire[
                    ELEMENT.code
                  ].Use_A_Nutrient_Balance_Calculator
                }
              />
            }
            label={"Use A Nutrient Balance Calculator"}
          />
          <FormControlLabel
            sx={{ display: "block" }}
            control={
              <Checkbox
                onChange={(e) =>
                  handleCheck(
                    ELEMENT.code,
                    "Consult_A_CCA_Qualified_Agronomist",
                    e.target.checked
                  )
                }
                checked={
                  fourRAssessmentContext.state.operationsQuestionnaire[
                    ELEMENT.code
                  ].Consult_A_CCA_Qualified_Agronomist
                }
              />
            }
            label={"Consult A CCA Qualified Agronomist"}
          />

          <FormControlLabel
            sx={{ display: "block" }}
            onChange={(e) =>
              handleCheck(
                ELEMENT.code,
                "Follow_Provincial_Recommendations",
                e.target.checked
              )
            }
            control={
              <Checkbox
                checked={
                  fourRAssessmentContext.state.operationsQuestionnaire[
                    ELEMENT.code
                  ].Follow_Provincial_Recommendations
                }
              />
            }
            label={"Follow Provincial Recommendations"}
          />
          <FormControlLabel
            sx={{ display: "block" }}
            control={
              <Checkbox
                onChange={(e) =>
                  handleCheck(
                    ELEMENT.code,
                    "Use_Variable_Rate_Prescription_Maps",
                    e.target.checked
                  )
                }
                checked={
                  fourRAssessmentContext.state.operationsQuestionnaire[
                    ELEMENT.code
                  ].Use_Variable_Rate_Prescription_Maps
                }
              />
            }
            label={"Use Variable Rate Prescription Maps"}
          />
        </Box>
      ))}
    </Box>
  );
}
