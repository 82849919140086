// import {
//   Stepper,
//   Step,
//   StepLabel,
//   Button,
//   Box,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Checkbox,
//   Typography,
// } from "@mui/material";
// import { FourRAssessmentContext } from "../../../App";
// import React, { useContext, useEffect, useState } from "react";

// export default function SoilTest({ data, index }) {
// const [data, setData] = useState({
//     nutrient: "",
//     test: "",
//     date: "",
//     result: "",
//     units: "",
//     frequency: "",
// })
//   return (
//     <TableRow>
//       <TableCell>{data.nutrient}</TableCell>   Nutrient Shuld be one of the following: const FertilizerTypes = [ { value: "N", label: "Nitrogen (N)" }, { value: "P", label: "Phosphorus (P)" }, { value: "K", label: "Potassium (K)" }, { value: "S", label: "Sulphur (S)" }, { value: "M", label: "Micronutrients" }, ];
//       <TableCell>{data.test}</TableCell> This should be a select mui to select between "Olsen", '20-24"'
//       <TableCell>{data.date}</TableCell> Should be a date picker that that saves the date in a state variable as "DD-MM-YYYY" but represents the date as a label when selected such as March 2024 (Spring)
//       <TableCell>{data.result}</TableCell> # Should be a number input field  that only accepts numbers from 0 to 100
//       <TableCell>{data.units}</TableCell> # Should be a value of the following "lbs/acre", "ppm",
//       <TableCell>{data.frequency}</TableCell> Can take one of the following values ["Yearly", "Every 5 years","Every 4 years","Every 3 years","Every 2 years","Two out of 3 years","Two out of 5 years"]
//     </TableRow>
//   );
// }

import {
  TableRow,
  TableCell,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Checkbox,
  Typography,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { FourRAssessmentContext } from "../../../App";
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const FertilizerTypes = [
  { value: "N", label: "Nitrogen (N)" },
  { value: "P", label: "Phosphorus (P)" },
  { value: "K", label: "Potassium (K)" },
  { value: "S", label: "Sulphur (S)" },
  { value: "M", label: "Micronutrients" },
];

const SoilTest = ({ data, index, error }) => {
  // Handle change for inputs

  const fourRAssessmentContext = useContext(FourRAssessmentContext);
  useEffect(() => {}, [fourRAssessmentContext.state]);

  const [formattedDate, setFormattedData] = useState("Date");

  function handleSoilTestUpdate(property, value) {
    const prev = { ...fourRAssessmentContext.state };
    prev.soilTests[index][property] = value;
    fourRAssessmentContext.setter(prev);
  }

  function handleSoilTestDelete() {
    const prev = { ...fourRAssessmentContext.state };
    prev.soilTests.splice(index, 1);
    fourRAssessmentContext.setter(prev);
  }

  function handleMoveUpDown(direction) {
    const prev = { ...fourRAssessmentContext.state };
    if (prev.soilTests.length <= 1) return;

    if (direction < 0) {
      // means move up
      if (index === 0) return;
    } else {
      // means move down
      if (index === prev.soilTests.length - 1) return;
    }

    let tmp = prev.soilTests[index];
    prev.soilTests[index] = prev.soilTests[index + direction];
    prev.soilTests[index + direction] = tmp;
    fourRAssessmentContext.setter(prev);
  }

  // Handle date change using dayjs for formatting
  const handleDateChange = (newDate) => {
    const month = dayjs(newDate).month();
    let season = "";

    if (month >= 2 && month <= 4) {
      season = "Spring";
    } else if (month >= 5 && month <= 7) {
      season = "Summer";
    } else if (month >= 8 && month <= 10) {
      season = "Fall";
    } else {
      season = "Winter";
    }

    const formattedDate = `${season}`;
    setFormattedData(formattedDate);

    if (newDate) {
      const value = "01-" + dayjs(newDate).format("MM-YYYY");

      const prev = { ...fourRAssessmentContext.state };
      prev.soilTests[index]["date"] = value;
      fourRAssessmentContext.setter(prev);
    }
  };

  //
  //
  //

  //
  //

  return (
    <TableRow>
      {/* Nutrient */}
      <TableCell>
        <FormControl fullWidth>
          <InputLabel>Nutrient</InputLabel>
          <Select
            value={data.nutrient}
            onChange={(e) => handleSoilTestUpdate("nutrient", e.target.value)}
            label="Nutrient"
            error={error.nutrient}
          >
            {FertilizerTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>

      {/* Test */}
      <TableCell>
        <FormControl fullWidth>
          <InputLabel>Test</InputLabel>
          <Select
            value={data.test}
            onChange={(e) => handleSoilTestUpdate("test", e.target.value)}
            label="Test"
            error={error.test}
          >
            <MenuItem value="Olsen">Olsen</MenuItem>
            <MenuItem value='0-24"'>0-24"</MenuItem>
            <MenuItem value="Bray">Bray</MenuItem>
          </Select>
        </FormControl>
      </TableCell>

      {/* Date */}
      <TableCell>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={formattedDate}
            views={["year", "month"]}
            value={data.date ? dayjs(data.date) : null}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
            sx={
              error.date
                ? {
                    "& .MuiOutlinedInput-root": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#d32f2f",
                      },
                    },
                  }
                : {}
            }

            // error={error.date}
          />
        </LocalizationProvider>
      </TableCell>

      {/* Result */}
      <TableCell>
        <TextField
          type="number"
          inputProps={{ min: 0, max: 100 }}
          label="Result"
          value={data.result}
          onChange={(e) => handleSoilTestUpdate("result", e.target.value)}
          fullWidth
        />
      </TableCell>

      {/* Units */}
      <TableCell>
        <FormControl fullWidth>
          <InputLabel>Units</InputLabel>
          <Select
            value={data.units}
            onChange={(e) => handleSoilTestUpdate("units", e.target.value)}
            label="Units"
            error={error.units}
          >
            <MenuItem value="Lbs/Acre">lbs/acre</MenuItem>
            <MenuItem value="ppm">ppm</MenuItem>
          </Select>
        </FormControl>
      </TableCell>

      {/* Frequency */}
      <TableCell>
        <FormControl fullWidth>
          <InputLabel>Frequency</InputLabel>
          <Select
            value={data.frequency}
            onChange={(e) => handleSoilTestUpdate("frequency", e.target.value)}
            label="Frequency"
            error={error.frequency}
          >
            <MenuItem value="Yearly">Yearly</MenuItem>
            <MenuItem value="Every 5 years">Every 5 years</MenuItem>
            <MenuItem value="Every 4 years">Every 4 years</MenuItem>
            <MenuItem value="Every 3 years">Every 3 years</MenuItem>
            <MenuItem value="Every 2 years">Every 2 years</MenuItem>
            <MenuItem value="Two out of 3 years">Two out of 3 years</MenuItem>
            <MenuItem value="Two out of 5 years">Two out of 5 years</MenuItem>
          </Select>
        </FormControl>
      </TableCell>

      <TableCell>
        <IconButton onClick={handleSoilTestDelete}>
          <ClearIcon />
        </IconButton>
        <IconButton disabled={index === 0} onClick={() => handleMoveUpDown(-1)}>
          <ArrowUpwardIcon />
        </IconButton>
        <IconButton
          disabled={index === fourRAssessmentContext.state.soilTests.length - 1}
          onClick={() => handleMoveUpDown(1)}
        >
          <ArrowDownwardIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default SoilTest;
