import { Divider } from "@mui/material";
import BooleanQuestion from "../FormInputElements/BooleanQuestion";
import MultiChoiceQuestion from "../FormInputElements/MultiChoiceQuestion";
import NumberQuestion from "../FormInputElements/NumberQuestion";
import { useState, useEffect } from "react";
// import FormSelectCultivationTractorField from "../FormInputElements/FormSelectCultivationTractorField";
// import FormSelectPlantingMachine from "../FormInputElements/FormSelectPlantingMachine";
// import FormSelectFertilizerTractorField from "../FormInputElements/FormSelectFertilizerTractorField";
// import FormSelectSprayerField from "../FormInputElements/FormSelectSprayerField";

import CultivationsForm from "./FieldOperationsSubForms/CultivationsForm";
import PlantingForm from "./FieldOperationsSubForms/PlantingForm";
import FertilizerApplicationsForm from "./FieldOperationsSubForms/FertilizerApplicationsForm";
import FertilizerRatesForm from "./FieldOperationsSubForms/FertilizerRatesForm";
import PesticidesForm from "./FieldOperationsSubForms/PesticidesForm";

import { FertilizerTypes } from "../../Assets/DataTypes";

import FertilizerTableField from "../../Components/FormInputElements/FertilizerTableField";

import {
  CropYearContext,
  FarmContext,
  GUI_CONTEXT,
  FourRAssessmentContext,
} from "../../App";
import { useContext } from "react";
import FourRAssessment from "./FourRAssessment/FourRAssessment";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../axiosFetchers";

import { useAuth } from "../Auth/useAuth";

export default function FieldOperationsForm({ LowerPanel, panelControls }) {
  const cropyearContext = useContext(CropYearContext);
  const farmContext = useContext(FarmContext);
  const fourRAssessmentContext = useContext(FourRAssessmentContext);
  const { cropyearId } = useParams();
  const { user } = useAuth();

  const GUI = useContext(GUI_CONTEXT);

  const [has4RAssessment, setHas4RAssessment] = useState(
    GUI.state.has4RAssessment
  );

  useEffect(() => {
    const element = document.getElementById("scrollableDiv");
    element.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (cropyearId)
      axiosInstance
        .get(`${process.env.REACT_APP_API_URL}/assessments/${cropyearId}`, {
          headers: {
            token: "Bearer " + user.token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let isFalse = response.data === false;
          setHas4RAssessment(!isFalse);
          if (!isFalse) {
            fourRAssessmentContext.setter({ ...response.data });
          }
        });
  }, []);

  function handleOptInOut4R(e) {
    setHas4RAssessment(e.target.value === "true");
    GUI.setter({
      ...GUI.state,
      has4RAssessment: e.target.value === "true",
      completed4R: e.target.value === "true",
    });
  }

  return (
    <div className=" w-full h-full">
      <h3 className="text-[rgb(102,102,102)] text-[30px]">Field Operations</h3>
      <Divider sx={{ marginBottom: 3 }} />

      <CultivationsForm />
      <PlantingForm />
      <FertilizerApplicationsForm />

      <BooleanQuestion
        handleChange={handleOptInOut4R}
        fieldValue={has4RAssessment}
        modalTitle={"4R Stewardship Project"}
        modalDescription={
          "The 4R Stewardship Project consists in a series of advanced questions."
        }
        fieldLabel={
          "Would you like to fill out our advanced fertilizer survey?"
        }
      />
      {!has4RAssessment ? <FertilizerRatesForm /> : <FourRAssessment />}

      <PesticidesForm />

      <LowerPanel
        activeStep={panelControls.activeStep}
        handleBack={panelControls.handleBack}
        handleNext={panelControls.handleNext}
        steps={panelControls.steps}
        handleComplete={panelControls.handleComplete}
        completedSteps={panelControls.completedSteps}
        totalSteps={panelControls.totalSteps}
        isInputValid={() => true}
      />
    </div>
  );
}
