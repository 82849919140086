import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import SupportIcon from "../../../Assets/Icons/SupportIcon";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, TextField } from "@mui/material";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  minWidth: 436,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 0,
  color: "red",
  borderRadius: 1,
};

function FertilizerIngredientModal({
  open,
  handleClose,
  handleAddNewIngredient,
}) {
  const [name, setName] = useState("");
  const [N, setN] = useState(0);
  const [P, setP] = useState(0);
  const [K, setK] = useState(0);
  const [S, setS] = useState(0);

  function resetState() {
    setName("");
    setN(0);
    setP(0);
    setK(0);
    setS(0);
  }
  return (
    <Modal
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/*  */}
        <div className="flex items-center justify-center relative bg-[#00abec] px-[24px] py-[16px] h-[101px] w-full">
          <SupportIcon color={"#FFFFFF"} width="60px" height="60px" />

          {/*  Close Modal Button   */}
          <IconButton
            sx={{ position: "absolute", right: 2, top: 1, color: "#FFFFFF" }}
            onClick={() => handleClose(false)}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>

        {/* Modal Body */}
        <div className="flex flex-col w-full px-6  py-5">
          <h3 className="text-lg  font-medium text-[#00abec] mb-4">
            Add a new Ingredient
          </h3>

          <div className="flex flex-col  w-full  items-center justify-center">
            <TextField
              label="Ingredient Name"
              sx={{ marginBottom: 2, width: "100%" }}
              InputProps={{ inputProps: { min: 0 } }}
              variant="standard"
              value={name}
              placeholder="Enter an Ingredient's name e.g 'Urea'"
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              label="Nitrogen"
              sx={{ marginBottom: 2, width: "100%" }}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              variant="standard"
              value={N}
              onChange={(e) => setN(parseFloat(e.target.value) || 0)}
            />
            <TextField
              label="Phosphorous"
              sx={{ marginBottom: 2, width: "100%" }}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              variant="standard"
              value={P}
              onChange={(e) => setP(parseFloat(e.target.value) || 0)}
            />
            <TextField
              label="Potassium"
              sx={{ marginBottom: 2, width: "100%" }}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              variant="standard"
              value={K}
              onChange={(e) => setK(parseFloat(e.target.value) || 0)}
            />
            <TextField
              label="Sulphur"
              sx={{ marginBottom: 2, width: "100%" }}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              variant="standard"
              value={S}
              onChange={(e) => setS(parseFloat(e.target.value) || 0)}
            />
          </div>
          <Button
            onClick={() => {
              resetState();
              handleAddNewIngredient({ name: name, N: N, P: P, K: K, S: S });
            }}
            variant="contained"
            sx={[
              {
                fontSize: "16px",
                color: "white",
                backgroundColor: "#00abec",
                textTransform: "none",
              },
              (theme) => ({
                "&:hover": {
                  backgroundColor: "#006d97",
                },
              }),
            ]}
          >
            Save
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default FertilizerIngredientModal;
