import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { Save as SaveIcon } from "@mui/icons-material";
import FertilizerIngredientModal from "./FertilizerIngredientModal";
import { axiosInstance } from "../../axiosFetchers";
// import { FERTILIZER_MIX_CREATOR } from "../../../Assets/contextFactories_V0.js";
import { useAuth } from "../../../Components/Auth/useAuth";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function FertilizeMixModal({ open, handleClose, handleSave }) {
  const [mixName, setMixName] = useState("");
  const [rows, setRows] = useState([]);
  const [totalPounds, setTotalPounds] = useState(0);
  const { user } = useAuth();
  const [NPKS, setNPKS] = useState("0-0-0-0");
  const [fertilizerIngredients, setFertilizerIngredients] = useState([]);
  // const fertilizerIngredients = {
  // xyz: {
  //   _id: "xyz",
  //   name: "Urea",
  //   N: 46,
  //   P: 0,
  //   K: 0,
  //   S: 0,
  // },
  //   zzz: {
  //     _id: "zzz",
  //     name: "Potash",
  //     N: 18,
  //     P: 46,
  //     K: 0,
  //     S: 0,
  //   },
  // };
  let [fertilizerIngredientsDict, setFertilizerIngredientsDic] = useState({});

  useEffect(() => {
    async function fetchFertIngredients() {
      const response = await axiosInstance.get(
        process.env.REACT_APP_API_URL + `/assessments/fertilizer_ingredients`,

        {
          headers: {
            token: "Bearer " + user.token,
            "Content-Type": "application/json",
          },
        }
      );

      setFertilizerIngredients(response.data);
      let tmpDict = {};
      for (const fert of response.data) {
        tmpDict[fert._id.$oid] = fert;
      }
      setFertilizerIngredientsDic(tmpDict);
    }
    fetchFertIngredients();
  }, []);

  const handlePostIngredient = async (ingredient) => {
    const response = await axiosInstance.post(
      process.env.REACT_APP_API_URL + `/assessments/fertilizer_ingredients`,
      {
        ...ingredient,
      },
      {
        headers: {
          token: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
      }
    );

    setFertilizerIngredients([...fertilizerIngredients, response.data.obj]);

    let newObj = {};
    newObj[response.data.obj._id.$oid] = response.data.obj;

    setFertilizerIngredientsDic({
      ...fertilizerIngredientsDict,
      ...newObj,
    });

    return response.data.obj._id.$oid;
  };

  const handleChange = (index, data) => {
    const cpyRows = [...rows];
    cpyRows.splice(index, 1, data);

    setRows(cpyRows);
  };
  const handleDelete = (index) => {
    const cpyRows = [...rows];
    cpyRows.splice(index, 1);

    setRows(cpyRows);
  };

  const handleAddFertilizerIngredient = () => {
    const cpy = [...rows];
    cpy.push({ ingredientId: "", amountInPounds: 0 });

    setRows(cpy);
  };
  function precise(num) {
    return Math.round(num * 100) / 100;
  }

  useEffect(() => {
    async function recalculateSummary() {
      let totalPounds = 0;
      for (const ROW of rows) {
        totalPounds += ROW.amountInPounds;
      }
      var lbs = 0;

      var pseudo_npks = { N: 0, P: 0, K: 0, S: 0 };

      for (const ROW of rows) {
        if (ROW.ingredientId) {
          // if (!(ROW.ingredientId in fertilizerIngredientsDict)) return;

          pseudo_npks.N +=
            fertilizerIngredientsDict[ROW.ingredientId]["N"] *
            (ROW.amountInPounds / totalPounds);
          pseudo_npks.P +=
            fertilizerIngredientsDict[ROW.ingredientId]["P"] *
            (ROW.amountInPounds / totalPounds);
          pseudo_npks.K +=
            fertilizerIngredientsDict[ROW.ingredientId]["K"] *
            (ROW.amountInPounds / totalPounds);
          pseudo_npks.S +=
            fertilizerIngredientsDict[ROW.ingredientId]["S"] *
            (ROW.amountInPounds / totalPounds);

          lbs += ROW.amountInPounds;
        }
      }
      setTotalPounds(lbs);
      if (
        [pseudo_npks.N, pseudo_npks.P, pseudo_npks.K, pseudo_npks.S].some(isNaN)
      )
        setNPKS("");
      else
        setNPKS(
          `${precise(pseudo_npks.N)}\u2015${precise(
            pseudo_npks.P
          )}\u2015${precise(pseudo_npks.K)}\u2015${precise(pseudo_npks.S)}`
        );
    }
    recalculateSummary();
  }, [rows, fertilizerIngredients, fertilizerIngredientsDict]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          Create a Fertilizer Mix
        </Typography>

        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1" component="div">
            Mix Name
          </Typography>

          <TextField
            value={mixName}
            onChange={(e) => setMixName(e.target.value)}
          ></TextField>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1" component="div">
            Mix Ingredients
          </Typography>

          <TableContainer component={Paper}>
            <Table aria-label="fertilizer table">
              <TableHead>
                <TableRow>
                  <TableCell>Fertilizer Name</TableCell>
                  <TableCell>Amount Applied (Lbs)</TableCell>
                  <TableCell>Units</TableCell>
                  <TableCell>Total N</TableCell>
                  <TableCell>Total P</TableCell>
                  <TableCell>Total K</TableCell>
                  <TableCell>Total S</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <IngredientRow
                    key={index}
                    data={row}
                    index={index}
                    handleChange={handleChange}
                    handleDelete={handleDelete}
                    handlePostIngredient={handlePostIngredient}
                    fertilizerIngredients={fertilizerIngredients}
                    fertilizerIngredientsDict={fertilizerIngredientsDict}
                  />
                ))}
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Button onClick={handleAddFertilizerIngredient}>
                      Add Fertilizer to Mix
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1" component="div">
            Your Summary
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Total Pounds of Fertilizer (lbs/ac)</TableCell>
                  <TableCell>NPKS</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{totalPounds || ""}</TableCell>{" "}
                  {/* Update with actual calculation */}
                  <TableCell>{NPKS}</TableCell>{" "}
                  {/* Update with actual calculation */}
                  <TableCell>
                    <IconButton
                      onClick={() =>
                        handleSave({ name: mixName, ingredients: rows })
                      }
                    >
                      <SaveIcon fontSize="large" sx={{ color: "#F15D22" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Modal>
  );
}
function IngredientRow({
  data,
  index,
  handleChange,
  handleDelete,
  handlePostIngredient,
  fertilizerIngredients,
  fertilizerIngredientsDict,
}) {
  const [localData, setLocalData] = useState(data);
  const [ingredientModalOpen, setIngredientModalOpen] = useState(false);

  useEffect(() => {
    setLocalData(data);
  }, [data, fertilizerIngredients, fertilizerIngredientsDict]);

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;

    const updatedLocalData = { ...localData, ingredientId: selectedId };

    handleChange(index, updatedLocalData);
  };
  const handleAmountChange = (event) => {
    const newAmount = parseFloat(event.target.value);
    const updatedLocalData = { ...localData, amountInPounds: newAmount };

    handleChange(index, updatedLocalData);
  };

  async function handleAddNewIngredient(ingredient) {
    setIngredientModalOpen(false);

    const id = await handlePostIngredient(ingredient);
    handleChange(index, { ...localData, ingredientId: id });
  }

  if (
    localData.ingredientId &&
    !(localData.ingredientId in fertilizerIngredientsDict)
  )
    return;
  return (
    <TableRow>
      <TableCell>
        <Select
          value={localData.ingredientId || ""}
          onChange={handleSelectChange}
          displayEmpty
          fullWidth
        >
          <MenuItem value="" disabled>
            Select Fertilizer
          </MenuItem>
          {fertilizerIngredients.map((fertilizer) => (
            <MenuItem key={fertilizer._id.$oid} value={fertilizer._id.$oid}>
              {fertilizer.name +
                " " +
                `(${fertilizer.N}-${fertilizer.P}-${fertilizer.K}-${fertilizer.S})`}
            </MenuItem>
          ))}
          <MenuItem>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setIngredientModalOpen(true);
              }}
            >
              Add Ingredient
            </Button>
          </MenuItem>

          <FertilizerIngredientModal
            open={ingredientModalOpen}
            handleClose={() => setIngredientModalOpen(false)}
            handleAddNewIngredient={handleAddNewIngredient}
          />
        </Select>
      </TableCell>
      <TableCell>
        <TextField
          type="number"
          value={localData.amountInPounds || 0}
          onChange={handleAmountChange}
          fullWidth
        />
      </TableCell>
      <TableCell>lbs/ac</TableCell>
      <TableCell>
        {parseInt(
          localData.ingredientId &&
            (fertilizerIngredientsDict[localData.ingredientId]["N"] / 100) *
              localData.amountInPounds
        ) || ""}
      </TableCell>
      <TableCell>
        {parseInt(
          localData.ingredientId &&
            (fertilizerIngredientsDict[localData.ingredientId]["P"] / 100) *
              localData.amountInPounds
        ) || ""}
      </TableCell>
      <TableCell>
        {parseInt(
          localData.ingredientId &&
            (fertilizerIngredientsDict[localData.ingredientId]["K"] / 100) *
              localData.amountInPounds
        ) || ""}
      </TableCell>
      <TableCell>
        {parseInt(
          localData.ingredientId &&
            (fertilizerIngredientsDict[localData.ingredientId]["S"] / 100) *
              localData.amountInPounds
        ) || ""}
      </TableCell>
    </TableRow>
  );
}
