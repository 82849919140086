import React, { PureComponent, useState, useContext, useEffect } from "react";
import { Margin, usePDF, Options } from "react-to-pdf";
import LineChart from "../Components/Charts/LineChart";
import HelpModal from "../Components/HelpModal";
import axios from "axios";
import * as Base64 from "js-base64";

import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import Page from "../Components/Page";
import FormSelectField from "../Components/FormInputElements/FormSelectField";
import SwitchQuestion from "../Components/FormInputElements/SwitchQuestion";
import OutlinedDownloadButton from "../Components/Buttons/OutlinedDownloadButton";

import IndicatorLandUse from "../Components/Indicators/IndicatorLandUse";
import IndicatorEnergyUse from "../Components/Indicators/IndicatorEnergyUse";
import IndicatorGHG from "../Components/Indicators/IndicatorGHG";
import IndicatorSoilErosion from "../Components/Indicators/IndicatorSoilErosion";

import {
  GetSetFarm,
  GetSetField,
  GetSetCropYear,
  GetSetAnalysis,
  axiosInstance,
} from "../Components/axiosFetchers";
import { useAuth } from "../Components/Auth/useAuth";
import { useParams } from "react-router-dom";
import AssessmentResultsTable from "../Components/CropYearForms/FourRAssessment/AssessmentResultsTable";
import {
  FarmContext,
  FieldContext,
  CropYearContext,
  ReportDataContext,
} from "../App";

export default function AnalysisPage({}) {
  const { user } = useAuth();
  const { farmId, fieldId, cropyearId } = useParams();

  document.title = "Analysis Results Page - Field To Market Canada";

  var hasUpdated = false;
  const options = {
    overrides: {
      canvas: {
        onclone: (document) => {
          document.getElementById("elementId").classList.toggle("visible");
        },
      },
    },
  };
  const [analysisError, setAnalysisErrorOpen] = useState(false);

  const handleClose = () => {
    window.location.reload();
  };

  var reportDataConext = useContext(ReportDataContext);

  const farmContext = useContext(FarmContext);
  const fieldContext = useContext(FieldContext);
  const cropYearContext = useContext(CropYearContext);
  const [spidergramData, setSpidergramData] = useState(false);

  useEffect(() => {
    async function analysisPageStartUp() {
      await GetSetFarm(farmId, user, farmContext);
      await GetSetField(farmId, fieldId, user, fieldContext);
      await GetSetCropYear(farmId, fieldId, cropyearId, user, cropYearContext);
      await GetSetAnalysis(cropyearId, user, reportDataConext);

      // handleNaNs();
      hasUpdated = true;
    }
    analysisPageStartUp();
  }, []);

  useEffect(() => {
    if (spidergramData || hasUpdated) return;
    if (
      typeof reportDataConext.state === "object" &&
      "indicators" in reportDataConext.state
    ) {
      setSpidergramData([
        {
          subject: "Land Use Efficiency",
          A:
            Number.parseFloat(
              reportDataConext.state["indicators"]["landUse"][2]
            ).toFixed(2) <= 200
              ? Number.parseFloat(
                  reportDataConext.state["indicators"]["landUse"][2]
                ).toFixed(2)
              : 200,
          fullMark: 200,
        },
        {
          subject: "Energy Use",
          A:
            Number.parseFloat(
              reportDataConext.state["indicators"]["energyUse"][2]
            ).toFixed(2) <= 200
              ? Number.parseFloat(
                  reportDataConext.state["indicators"]["energyUse"][2]
                ).toFixed(2)
              : 200,
          fullMark: 200,
        },
        {
          subject: "GHG Emissions",
          A:
            Number.parseFloat(
              reportDataConext.state["indicators"]["ghg"][2]
            ).toFixed(2) <= 200
              ? Number.parseFloat(
                  reportDataConext.state["indicators"]["ghg"][2]
                ).toFixed(2)
              : 200,
          fullMark: 200,
        },
        {
          subject: "Soil Erosion Risk",
          A:
            Number.parseFloat(
              reportDataConext.state["indicators"]["soilErosion"][2]
            ).toFixed(2) <= 200
              ? Number.parseFloat(
                  reportDataConext.state["indicators"]["soilErosion"][2]
                ).toFixed(2)
              : 200,
          fullMark: 200,
        },
      ]);
    }
  }, [reportDataConext]);

  async function getReportPDF() {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/cropyears/${cropyearId}/analysisPDF`,
      {},
      {
        headers: {
          token: "Bearer " + user.token,
        },
        responseType: "blob",
      }
    );

    const blob = new Blob([response.data], { type: "application/pdf" });

    const url = window.URL.createObjectURL(blob);

    // Create an anchor element and click it to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = `analysis.pdf`; // Set the file name
    document.body.appendChild(a);
    a.click();

    // Clean up
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const LiveAnalysis = () => {
    return (
      <div className="w-full h-full">
        <div className="w-full flex flex-col min-[1100px]:flex-row ">
          <div className="text-[#666666] max-w-[950px] mr-10">
            <h2 className="text-[24px] text-[rgba(0,0,0,0.87)] mb-4">
              Fieldprint Spidergram
            </h2>
            <p className="mb-4">
              Fieldprint results are shown on the spidergram as relative indices
              on a scale of 1-200 that represent your metric scores as compared
              to the provincial metrics. The indices are calculated so that
              smaller values indicate less resource use or environmental impact
              from your field when compared to the provincial averages. This
              illustration can be used to identify where the greatest
              opportunities for improvement are for your field, and over time
              can be used to evaluate progress and trade-offs between different
              sustainability metrics for your field.
            </p>

            <div className="flex w-full justify-center">
              <div className="w-[600px] h-[600px]">
                <SpiderChart data={spidergramData} />
              </div>
            </div>
            <p className="-mt-[200px] mb-6">
              Benchmarks represent an average based on provincial statistical
              data for the period 2011 - 2023 and provide context for how your
              scores relate to this known point. Benchmarks should not be
              interpreted as a specific level of sustainability, or a
              performance target. Provincial benchmarks not shown in the graphs
              are not available for the applicable metric
            </p>
            <div className="w-full mb-14 overflow-x-scroll">
              <LineChart />
            </div>
            <OutlinedDownloadButton onClick={getReportPDF} />
          </div>
          <AssessmentResultsTable />
        </div>
        <div className="w-full py-8 text-[#666666]">
          <h2 className="text-[24px] text-[rgba(0,0,0,0.87)] mb-4">
            Fieldprint Data per Indicator
          </h2>

          <IndicatorLandUse
            crop={reportDataConext.state.values[15]}
            year={reportDataConext.state.values[14]}
            fieldScore={Number.parseFloat(
              reportDataConext.state["indicators"]["landUse"][0]
            ).toFixed(2)}
            provincialScore={Number.parseFloat(
              reportDataConext.state["indicators"]["landUse"][1]
            ).toFixed(2)}
          />
          <IndicatorEnergyUse
            crop={reportDataConext.state.values[15]}
            year={reportDataConext.state.values[14]}
            fieldScore={Number.parseFloat(
              reportDataConext.state["indicators"]["energyUse"][0]
            ).toFixed(2)}
            provincialScore={Number.parseFloat(
              reportDataConext.state["indicators"]["energyUse"][1]
            ).toFixed(2)}
          />

          <IndicatorGHG
            crop={reportDataConext.state.values[15]}
            year={reportDataConext.state.values[14]}
            fieldScore={Number.parseFloat(
              reportDataConext.state["indicators"]["ghg"][0]
            ).toFixed(2)}
            provincialScore={Number.parseFloat(
              reportDataConext.state["indicators"]["ghg"][1]
            ).toFixed(2)}
          />

          <IndicatorSoilErosion
            crop={reportDataConext.state.values[15]}
            year={reportDataConext.state.values[14]}
            fieldScore={Number.parseFloat(
              reportDataConext.state["indicators"]["soilErosion"][0]
            ).toFixed(2)}
            provincialScore={Number.parseFloat(
              reportDataConext.state["indicators"]["soilErosion"][1]
            ).toFixed(2)}
          />
        </div>
      </div>
    );
  };

  return (
    <Page
      showQuickFacts={true}
      title={`${fieldContext.state.name || "field"} on ${
        farmContext.state.name || "Demo Farm"
      }`}
      headerBorderColor={"border-[#34a853]"}
    >
      <div className="h-full w-full ">
        {typeof reportDataConext.state === "object" &&
          "indicators" in reportDataConext.state && <LiveAnalysis />}
      </div>
      <HelpModal
        title={"Error"}
        description={"We ran into problems while processing your analyis."}
        open={analysisError}
        handleClose={handleClose}
      />
    </Page>
  );
}

class SpiderChart extends PureComponent {
  // static demoUrl = "https://codesandbox.io/s/simple-radar-chart-rjoc6";

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="40%" cy="30%" outerRadius="48%" data={this.props.data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" />
          <PolarRadiusAxis domain={[0, 200]} />
          <Radar
            name="Field Score"
            dataKey="A"
            stroke="#008CC3"
            fill="#00A4E5"
            fillOpacity={0.6}
            activeDot={{ r: 6 }}
          />
          <Tooltip />
        </RadarChart>
      </ResponsiveContainer>
    );
  }
}
