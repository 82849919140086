import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import SupportIcon from "../../../Assets/Icons/SupportIcon";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, TextField } from "@mui/material";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import MachineTemplates from "../../../Assets/PlanterObjs.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  minWidth: 436,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 0,
  color: "red",
  borderRadius: 1,
};

function NewMachinePlanterModal({
  open,
  handleClose,
  handleAddNewMachine,
  machineLabel,
  machineType = "TRACTOR",
  isGeneric = false,
}) {
  const [newMachineName, setNewMachineName] = useState("");
  const [newMachineHP, setNewMachineHP] = useState(0);
  const [newMachineACHR, setNewMachineACHR] = useState(0);
  const [MachineType, setMachineType] = useState(machineType);

  const formattedMachineType =
    MachineType.slice(0, 1) + MachineType.slice(1).toLowerCase();

  function AutocompleteSelect() {
    return (
      <Autocomplete
        options={MachineTemplates[MachineType]}
        autoHighlight
        onInputChange={(event, newInputValue) => {
          if (
            Math.min(
              newInputValue.indexOf("HP"),
              newInputValue.indexOf("AcreHour")
            ) < 0
          ) {
            return;
          }
          let parsedData = parsePlanterInfo(newInputValue);
          setNewMachineName(parsedData.name);
          setNewMachineHP(parsedData.HP);
          setNewMachineACHR(parsedData.acreHour);
        }}
        getOptionLabel={(option) =>
          option.brand +
          " " +
          option.model +
          "  " +
          `HP: ${option.HP}, AcreHour: ${option.acreHour}`
        }
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <Box
              key={key}
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...optionProps}
            >
              {option.brand} - {option.model}
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`Choose a ${formattedMachineType} Preset From Template`}
            inputProps={{
              ...params.inputProps,
            }}
            variant="standard"
          />
        )}
      />
    );
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/*  */}
        <div className="flex items-center justify-center relative bg-[#00abec] px-[24px] py-[16px] h-[101px] w-full">
          <SupportIcon color={"#FFFFFF"} width="60px" height="60px" />

          {/*  Close Modal Button   */}
          <IconButton
            sx={{ position: "absolute", right: 2, top: 1, color: "#FFFFFF" }}
            onClick={() => handleClose(false)}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>

        {/* Modal Body */}
        <div className="flex flex-col w-full px-6  py-5">
          <h3 className="text-lg  font-medium text-[#00abec] mb-4">
            Add a new {machineLabel} to your farm <AgricultureIcon />
          </h3>

          <div className="flex flex-col  items-center justify-center">
            {isGeneric && (
              <FormControl
                variant="standard"
                sx={{ marginBottom: "8px", width: "100%" }}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Equipment Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={MachineType}
                  onChange={(e) => setMachineType(e.target.value)}
                  label="Equipment Type"
                >
                  <MenuItem value={"TRACTOR"}>Tractor</MenuItem>
                  <MenuItem value={"COMBINE"}>Combine</MenuItem>
                  <MenuItem value={"PLANTER"}>Planter</MenuItem>
                  <MenuItem value={"SWATHER"}>Swather</MenuItem>
                  <MenuItem value={"SPRAYER"}>Sprayer</MenuItem>
                </Select>
              </FormControl>
            )}
            <div className="mb-4 w-full">
              <AutocompleteSelect />
            </div>
            <TextField
              id="standard-basic"
              label={`${machineLabel} Name`}
              variant="standard"
              sx={{ marginBottom: 2, width: "100%" }}
              onChange={(e) => setNewMachineName(e.target.value)}
              value={newMachineName}
            />
            <TextField
              id="standard-basic"
              label={`${machineLabel} Name`}
              variant="standard"
              sx={{ marginBottom: 2, width: "100%" }}
              onChange={(e) => setNewMachineName(e.target.value)}
              value={newMachineName}
            />
            <TextField
              id="standard-basic"
              label="Horse Power (HP)"
              sx={{ marginBottom: 2, width: "100%" }}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              variant="standard"
              onChange={(e) => setNewMachineHP(e.target.value)}
              value={newMachineHP}
            />
            <TextField
              id="standard-basic"
              label="Default ac/hr"
              variant="standard"
              sx={{ marginBottom: 2, width: "100%" }}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              onChange={(e) => setNewMachineACHR(e.target.value)}
              value={newMachineACHR}
            />
          </div>
          <div className="flex w-full justify-center">
            <Button
              onClick={() => {
                handleClose(false);
                handleAddNewMachine({
                  type: MachineType,
                  name: newMachineName,
                  HP: newMachineHP,
                  fuelUse: null,
                  defaultAcreHour: newMachineACHR,
                });
              }}
              variant="contained"
              sx={[
                {
                  fontSize: "16px",
                  color: "white",
                  backgroundColor: "#00abec",
                  textTransform: "none",
                },
                (theme) => ({
                  "&:hover": {
                    backgroundColor: "#006d97",
                  },
                }),
              ]}
            >
              Save
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default NewMachinePlanterModal;

function parsePlanterInfo(input) {
  // Regular expression to match the name, HP, and AcreHour
  const nameRegex = /^(.+?)\s*HP:/;
  const hpRegex = /HP:\s*(\d+)/;
  const acreHourRegex = /AcreHour:\s*(\d+)/;

  // Extracting the name using the regular expression
  const nameMatch = input.match(nameRegex);
  const hpMatch = input.match(hpRegex);
  const acreHourMatch = input.match(acreHourRegex);

  // Returning an object with the parsed name, HP, and acreHour
  return {
    name: nameMatch ? nameMatch[1].trim() : null,
    HP: hpMatch ? parseInt(hpMatch[1], 10) : null,
    acreHour: acreHourMatch ? parseInt(acreHourMatch[1], 10) : null,
  };
}
